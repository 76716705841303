$fgColor: #000;
$fillColor: #f0f0f0;
$controlBorderColor: #808080;
$controlBgColor: #fff;

$invertedTextColor: #f0f0f0;
$invertedBgColor: #404040;

$textColor: #202020;
$textSecondaryColor: #808080;
$textDisabledColor: rgba($textColor, 0.5);
$errorTextColor: #c00000;
$titleFontSize: 1.15em; // relative to body font

$borderRadius: 0.4em;
