@import '../../theme.scss';

.CSVImporter_FormatDataRowPreview {
  max-height: 12em;
  min-height: 6em;
  border: 1px solid $controlBorderColor;
  overflow: scroll;

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    > thead > tr > th {
      font-style: italic;
      font-weight: normal;
      color: $textSecondaryColor;
    }

    > thead > tr > th,
    > tbody > tr > td {
      border-right: 1px solid rgba($controlBorderColor, 0.5);
      padding: 0.5em 0.5em;
      line-height: 1;
      font-size: 0.75em;
      white-space: nowrap;

      &:last-child {
        border-right: none;
      }
    }

    // shrink space between rows
    > thead + tbody > tr:first-child > td,
    > tbody > tr + tr > td {
      padding-top: 0;
    }
  }
}
