@import '../theme.scss';

.CSVImporter_IconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; // override default
  width: 3em;
  height: 3em;
  border: 0;
  padding: 0;
  border-radius: 50%;
  background: transparent;
  font-size: inherit;
  color: $fgColor;
  cursor: pointer;

  &:hover:not(:disabled) {
    background: rgba($controlBorderColor, 0.25);
  }

  &:disabled {
    cursor: default;
  }

  &[data-small='true'] {
    width: 2em;
    height: 2em;
  }

  &[data-focus-only='true'] {
    opacity: 0;
    pointer-events: none;

    &:focus {
      opacity: 1;
    }
  }

  > span {
    display: block;
    width: 1.75em;
    height: 1.75em;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-type='back'] {
      // MUI ChevronLeft
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNmwtNiA2IDYgNiAxLjQxLTEuNDFMMTAuODMgMTJ6Ij48L3BhdGg+PC9zdmc+');
    }

    &[data-type='forward'] {
      // MUI ChevronRight
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwIDZMOC41OSA3LjQxIDEzLjE3IDEybC00LjU4IDQuNTlMMTAgMThsNi02eiI+PC9wYXRoPjwvc3ZnPg==');
    }

    &[data-type='replay'] {
      // MUI Replay
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyIDVWMUw3IDZsNSA1VjdjMy4zMSAwIDYgMi42OSA2IDZzLTIuNjkgNi02IDYtNi0yLjY5LTYtNkg0YzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LTgtMy41OC04LTgtOHoiPjwvcGF0aD48L3N2Zz4=');
    }

    &[data-type='arrowBack'] {
      // MUI ArrowBack
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTIwIDExSDcuODNsNS41OS01LjU5TDEyIDRsLTggOCA4IDggMS40MS0xLjQxTDcuODMgMTNIMjB2LTJ6Ij48L3BhdGg+PC9zdmc+');
    }

    &[data-type='close'] {
      // MUI Close
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZvY3VzYWJsZT0iZmFsc2UiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiPjwvcGF0aD48L3N2Zz4=');
    }
  }

  &:disabled > span {
    opacity: 0.25;
  }

  &[data-small='true'] > span {
    font-size: 0.75em;
  }
}
