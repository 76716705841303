@import '../../theme.scss';

.CSVImporter_ColumnDragCard {
  position: relative;
  z-index: 0; // reset stacking context
  padding: 0;
  // padding: 0.5em 0.75em;
  border-radius: $borderRadius;
  background: $controlBgColor;
  box-shadow: 0 1px 1px rgba(#000, 0.25);
  cursor: default;

  &[data-draggable='true'] {
    cursor: grab;

    // avoid triggering scroll on iOS Safari (needed despite preventDefault also being used)
    touch-action: none;
  }

  &[data-dummy='true'] {
    border-radius: 0;
    background: $fillColor;
    box-shadow: none;
    opacity: 0.5;
    user-select: none;
  }

  &[data-error='true'] {
    background: rgba($errorTextColor, 0.25);
    color: $textColor;
  }

  &[data-shadow='true'] {
    background: $fillColor;
    box-shadow: none;
    color: rgba($textColor, 0.25); // reduce text
  }

  &[data-drop-indicator='true'] {
    box-shadow: 0 1px 2px rgba(#000, 0.5);
    color: $fgColor;
  }

  &__cardHeader {
    margin-top: -0.25em;
    margin-right: -0.5em;
    margin-bottom: 0.25em;
    margin-left: -0.5em;
    height: 1.5em; // sized to be covered by small button
    font-weight: bold;
    color: $textSecondaryColor;

    & > b {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: $fillColor;
      line-height: 1; // centered by parent anyway
    }

    > var {
      display: block;
      margin-bottom: -1px;
      width: 1px; // non-zero size for reader
      height: 1px;
      overflow: hidden;
    }
  }

  &__cardPaper[data-draggable='true']:hover &__cardHeader,
  &__cardPaper[data-dragged='true'] &__cardHeader {
    color: $fgColor;
  }

  &__cardValue {
    margin-top: 0.25em;
    overflow: hidden;
    line-height: 1.25em; // might not be inherited from main content
    font-size: 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;

    &[data-header='true'] {
      text-align: center;
      font-style: italic;
      color: $textSecondaryColor;
    }

    & + div {
      margin-top: 0;
    }
  }

  &[data-shadow='true'] > &__cardValue[data-header='true'] {
    // color: rgba($textSecondaryColor, 0.25); // reduce text
    color: rgba($textColor, 0.25); // reduce text
  }
}
