body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiPickersDay-root,
.MuiCalendarPicker-root .MuiTypography-caption {
  font-size: 1em !important;
}

.PrivatePickersSlideTransition-root {
  margin-bottom: -20px;
}

.Polaris-Navigation__Icon svg {
  fill: #3d5a80 !important;
}

.Polaris-Navigation__Item--selected {
  color: #3d5a80 !important;
}

.Polaris-Navigation__Item--selected::before {
  background-color: #3d5a80 !important;
}

.MuiChip-label {
  font-size: 14px;
}

.Polaris-Banner__Ribbon {
  align-items: center;
  display: flex;
}

.MuiStepLabel-iconContainer svg {
  width: 23px;
  height: 22px;
}

#column-seed_units_delivered_with_uom div,
#column-seed_weight_with_uom div {
  text-align: right;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.MuiInputBase-formControl,
.MuiDataGrid-cell {
  outline: none !important;
}

.notifications-table .rdt_TableRow div {
  width: 100%;
}
